import { extendTheme } from '@chakra-ui/react';

import { createBreakpoints } from '@chakra-ui/theme-tools';

const breakpoints = createBreakpoints({
  sm: '53em',
  md: '60em',
  lg: '70em',
  xl: '80em',
  '2xl': '96em',
});

const theme = extendTheme({
  colors: {
    brand: {
      300: 'rgba(255,255,255,0.66)',
      400: '#FAFAFA',
      500: '#070604',
      600: '#00adaa',
      700: '#EEEEEE',
      800: '#f49825',
    },
  },
  fonts: {
    body: 'Courier, sans-serif',
    heading: 'Impact, League Gothic, sans-serif',
  },
  breakpoints,
});

export default theme;
